<template></template>

<script>
// import Card from "../dashboard/component/Card";

export default {
  name: "index",
};
</script>

<style scoped>
</style>
